.shipping-points-list {
  display: grid;
  grid-row-gap: 16px;
  row-gap: 16px;
}
.shipping-points-list__empty {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 18px;
}

.shipping-point {
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px 0;
  font-size: 14px;
  border-width: 0 !important;
}
.shipping-point--selected {
  font-weight: bold;
}

.shipping-points-list-plug {
  display: flex;
  font-size: 35px;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}