@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.danger {
  color: red;
}

/* размер шрифтов */
.font-size-gt {
  font-size: 36.26px;
}

.font-size-bg-2 {
  font-size: 19px;
}

.font-size-bg {
  font-size: 18px;
}

.font-size-lg {
  font-size: 17px;
}

.font-size-md {
  font-size: 14px;
}

.font-size-sm {
  font-size: 12px;
}

.font-size-mega {
  font-size: 30px;
}

.m-h-auto {
  margin-left: auto;
  margin-right: auto;
}

.circle {
  border-radius: 50%;
}

.angle {
  border-radius: 0;
}

.flex {
  display: flex;
}

.flex-reverse {
  flex-direction: row-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-basis-auto {
  flex-basis: auto;
}

.bold {
  font-weight: bold;
}

.line-height-xs {
  line-height: 1;
}

.line-height-sm {
  line-height: 1.5;
}

.line-height-md {
  line-height: 1.6;
}

.full-width {
  width: 100%;
  box-sizing: border-box;
}

.p-v-gt {
  padding-top: 70px;
  padding-bottom: 70px;
}

.weight-100 {
  font-weight: 100;
}

.flex-shrink-n {
  flex-shrink: 0;
}

.light {
  font-weight: 300;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.vertical-middle {
  vertical-align: middle;
}

/* отступы */
.m-v-xs {
  margin: 5px 0;
}

.m-v-sm {
  margin: 10px 0;
}

.m-v-md {
  margin: 15px 0;
}

.m-v-lg {
  margin: 20px 0;
}

.m-v-wl {
  margin-top: 30px;
  margin-bottom: 30px;
}

.m-t-n {
  margin-top: 0;
}

.m-t-xs {
  margin-top: 5px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-t-md {
  margin-top: 15px;
}

.m-t-lg {
  margin-top: 20px;
}

.m-t-wl {
  margin-top: 30px;
}

.m-l-xs {
  margin-left: 5px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-l-md {
  margin-left: 15px;
}

.m-l-lg {
  margin-left: 20px;
}

.m-r-none {
  margin-right: 0;
}

.m-r-xs {
  margin-right: 5px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-r-md {
  margin-right: 15px;
}

.m-r-lg {
  margin-right: 20px;
}

.m-r-wl {
  margin-right: 30px;
}

.m-b-n {
  margin-bottom: 0;
}

.m-b-xs {
  margin-bottom: 5px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-b-md {
  margin-bottom: 15px;
}

.m-b-lg {
  margin-bottom: 20px;
}

.m-b-wl {
  margin-bottom: 30px;
}

.m-b-gt {
  margin-bottom: 50px;
}

.m-b-giga {
  margin-bottom: 90px;
}

.m-align {
  margin: auto;
}

.p-g-gt {
  padding-left: 60px;
  padding-right: 60px;
}

.p-g-none {
  padding-left: 0;
  padding-right: 0;
}

.p-r-none {
  padding-right: 0;
}

.p-l-none {
  padding-left: 0;
}

.p-v-xs {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-v-sm {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-v-md {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p-v-lg {
  padding-top: 20px;
  padding-bottom: 20px;
}

.p-v-giga {
  padding-left: 131px;
  padding-right: 131px;
}

.p-wl {
  padding: 30px;
}

.p-l-n {
  padding-left: 0;
}

.p-l-xs {
  padding-left: 5px;
}

.p-l-sm {
  padding-left: 10px;
}

.p-l-md {
  padding-left: 15px;
}

.p-l-lg {
  padding-left: 20px;
}

.p-l-wl {
  padding-left: 30px;
}

.p-r-n {
  padding-right: 0;
}

.p-r-xs {
  padding-right: 5px;
}

.p-r-sm {
  padding-right: 10px;
}

.p-r-md {
  padding-right: 15px;
}

.p-r-lg {
  padding-right: 20px;
}

.p-r-wl {
  padding-right: 30px;
}

.p-t-xs {
  padding-top: 5px;
}

.p-t-sm {
  padding-top: 10px;
}

.p-t-md {
  padding-top: 15px;
}

.p-t-lg {
  padding-top: 20px;
}

.p-t-wl {
  padding-top: 30px;
}

.p-t-gt {
  padding-top: 50px;
}

.p-t-n {
  padding-top: 0;
}

.p-b-xs {
  padding-bottom: 5px;
}

.p-b-sm {
  padding-bottom: 10px;
}

.p-b-md {
  padding-bottom: 15px;
}

.p-b-lg {
  padding-bottom: 20px;
}

.p-b-wl {
  padding-bottom: 30px;
}

.p-b-gt {
  padding-bottom: 50px;
}

.p-b-n {
  padding-bottom: 0;
}

.full-height {
  height: 100%;
}

.hidden {
  visibility: hidden;
  height: 1px;
}

.outline-none {
  outline: none;
}

@media (min-width: 48em) {
  .p-v-gt {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.pointer-events-none {
  pointer-events: none;
}

.text-floating {
  word-wrap: break-word;
  word-wrap: break-word;
  word-break: keep-all;
  line-break: normal;
  -webkit-hyphens: none;
          hyphens: none;
}

.text-overflow, .text-static {
  word-wrap: normal; /* не поддерживает IE, Firefox; является копией word-wrap */
  word-wrap: normal;
  word-break: normal; /* не поддерживает Opera12.14, значение keep-all не поддерживается IE, Chrome */
  line-break: auto; /* нет поддержки для русского языка */
  -webkit-hyphens: manual;
          hyphens: manual;
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.overflow-hidden-for-modal-ios {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
  pointer-events: none;
}

.vertical-interval-xsmall {
  margin: 0.3125rem 0;
}

.vertical-interval-small {
  margin-top: 0.4375rem;
  margin-bottom: 0.4375rem;
}

.vertical-interval-middle {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.vertical-interval-big {
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
}

.vertical-interval-xsmall.no-top,
.vertical-interval-small.no-top,
.vertical-interval-middle.no-top,
.vertical-interval-big.no-top {
  margin-top: 0;
}

.vertical-interval-xsmall.no-bottom,
.vertical-interval-small.no-bottom,
.vertical-interval-middle.no-bottom,
.vertical-interval-big.no-bottom {
  margin-bottom: 0;
}

.horizontal-interval-xsmall {
  margin: 0 0.3125rem;
}

.horizontal-interval-small {
  margin: 0 0.4375rem;
}

.horizontal-interval-middle {
  margin: 0 0.625rem;
}

.horizontal-interval-big {
  margin: 0 0.9375rem;
}

.horizontal-interval-xsmall.no-left,
.horizontal-interval-small.no-left,
.horizontal-interval-middle.no-left,
.horizontal-interval-big.no-left {
  margin-right: 0;
}

.horizontal-interval-xsmall.no-right,
.horizontal-interval-small.no-right,
.horizontal-interval-middle.no-right,
.horizontal-interval-big.no-right {
  margin-left: 0;
}

.thin-scroll {
  scrollbar-width: thin;
}
.thin-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
          appearance: none;
}
.thin-scroll::-webkit-scrollbar:vertical {
  width: 11px;
}
.thin-scroll::-webkit-scrollbar:horizontal {
  height: 11px;
}
.thin-scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.4);
}
.thin-scroll::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}