@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.auth-social__title {
  font-size: 24px;
  padding: 0;
}
.auth-social__btns-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, 48px);
  grid-gap: 14px;
}
.auth-social__btn {
  display: inline-flex;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.auth-social__btn:hover {
  opacity: 0.8;
}
.auth-social__btn--fb {
  background-color: #1976d2;
}
.auth-social__btn--vk {
  background-color: #2787f5;
}
.auth-social__btn--google {
  background-color: #dd4b39;
}
.auth-social__btn--mail {
  background-color: #fc2c38;
}
.auth-social__btn--ok {
  background-color: #ee8208;
}
.auth-social__btn--ya {
  background-color: #f00;
}
.auth-social__btn-icon {
  display: flex;
}
.auth-social__btn-icon svg {
  display: block;
}
.auth-social__btn-text {
  display: none;
}

.modal-login-open-id {
  width: auto;
}
.modal-login-open-id .modal-content {
  padding: 20px 32px 32px;
}
.modal-login-open-id .auth-social__btns-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 0;
}
.modal-login-open-id .auth-social__title {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 16px;
}
.modal-login-open-id .auth-social__favorite-btns-wrap {
  display: grid;
  grid-gap: 16px;
  margin-right: 0;
}
.modal-login-open-id .auth-social__btn {
  margin-left: 7px;
  margin-right: 7px;
}
.modal-login-open-id .auth-social__btn:nth-child(-n+3) {
  display: grid;
  grid-template-columns: 33px calc(100% - 33px);
  grid-gap: 20px;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 22px;
  font-size: 16px;
  color: #fff;
  flex-basis: 100%;
  flex-shrink: 1;
  margin-bottom: 15px;
  width: auto;
  height: auto;
  border-radius: 5px;
  margin-left: 0;
  margin-right: 7px;
}
.modal-login-open-id .auth-social__btn:nth-child(-n+3) .auth-social__btn-text {
  font-size: 16px;
  text-align: center;
  display: block;
}
.modal-login-open-id .auth-social__btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 48em) {
  .modal-login-open-id .auth-social__btns-wrap {
    max-width: 328px;
  }
  .modal-login-open-id .auth-social__btn-text {
    padding-right: 20px;
    padding-left: 20px;
  }
}