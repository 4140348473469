.zone-dialog {
  min-width: 18.75rem;
}

.zone-dialog-title {
  font-size: 24px;
  padding: 0.625rem 0.625rem 0;
}

.zone-dialog .modal-content {
  padding: 0;
  min-height: 264px;
}

.zone-dialog-items {
  display: flex;
}

.zone-dialog-item {
  flex-grow: 1;
  vertical-align: top;
  padding-right: 0.625rem;
}

.zone-dialog-item-link {
  display: block;
  padding: 0.625rem;
  border-radius: 0.3125rem;
  border: 1px solid transparent;
}
.zone-dialog-item-link:hover {
  border-color: #dedddd;
}

.zone-dialog-text {
  font-size: 1rem;
  padding-right: 0.625rem;
}

.zone-dialog-countries,
.zone-dialog-autocomplete-block,
.zone-dialog-cities {
  padding: 0.625rem 0.625rem 0;
}

.zone-dialog-cities {
  padding-bottom: 0.625rem;
  border-top: 1px solid #e2e3e4;
}

.zone-dialog-autocomplete-block {
  margin-bottom: 0.9375rem;
}

.zone-dialog-input-wrap {
  position: relative;
}

.zone-dialog-input-wrap > input {
  font-size: 0.8rem;
  line-height: inherit;
}

.zone-dialog-country-link {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  font-size: 0.875rem;
  padding: 0.625rem;
  border: 0.0625rem transparent solid;
  border-radius: 0.3125rem;
}

.zone-dialog-country-image {
  width: 30px;
  height: 18px;
  margin-right: 5px;
  display: none;
}

.zone-dialog-country-selected {
  border-color: #ccc;
}

.zone-dialog-loading {
  text-align: center;
  padding: 40px 20px;
}

.zone-dialog-country-RU {
  display: inline-block;
  background-image: url("../../../../images/zone-img/rus.svg");
}

.zone-dialog-country-UA {
  display: inline-block;
  background-image: url("../../../../images/zone-img/ua.svg");
}

.zone-dialog-country-BY {
  display: inline-block;
  background-image: url("../../../../images/zone-img/by.svg");
}

.zone-dialog-country-KZ {
  display: inline-block;
  background-image: url("../../../../images/zone-img/kz.svg");
}

.zone-dialog-item--scale .zone-dialog-item {
  font-size: 16px;
}
.zone-dialog-item--scale .zone-dialog-title,
.zone-dialog-item--scale .zone-dialog-item-link {
  text-align: center;
}