.cart-discount .cart-mini-result-value {
  color: #ce1515;
}

.cart-mini {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 0.75rem;
}

.cart-mini-main-link {
  display: inline-block;
  padding: 0.3125rem 0.625rem 0.3125rem 0.375rem;
  -webkit-text-decoration: none;
  text-decoration: none;
  position: relative;
  z-index: 20;
  border: 1px solid #e2e3e4;
  border-radius: 0.3125rem;
}
.cart-mini-main-link::before {
  font-size: 0.875rem;
}
.cart-mini-main-link:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.cart-mini-block {
  position: absolute;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.25);
  border-radius: 0.3125rem;
  text-align: left;
  min-width: 330px;
  top: -3px;
  right: -3px;
  padding-top: 2.5rem;
}

.cart-mini-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  overflow-x: hidden;
}
.cart-mini-list .cart-mini-item .row {
  margin: 0;
}

.cart-mini-processing {
  opacity: 0;
}

.cart-mini-photo,
.cart-mini-info {
  padding: 0.3125rem;
  font-size: 0.6875rem;
}

.cart-mini-photo {
  margin: 0;
  text-align: center;
}

.cart-mini-result-name {
  font-weight: bold;
}

.cart-mini-name {
  font-size: 14px;
}

.cart-mini-result-block {
  background-color: #ebebec;
  padding: 0.9375rem;
  border-bottom-left-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.cart-mini-buttons {
  margin-top: 0.3125rem;
}

.cart-mini-scrollbar {
  max-height: 15rem;
  position: relative;
  overflow: auto;
}

.cart-mini-scrollbar-block {
  padding: 0.3125rem 0;
  position: relative;
}

.cart-mini-fixed {
  position: fixed;
  top: -40px;
}

.cart-full {
  width: 100%;
  padding: 0;
  margin: 0 0 1.0625rem;
  table-layout: fixed;
  border: 0;
}
.cart-full-help-trigger .help-trigger-icon {
  padding: 0;
  height: auto;
  width: auto;
}
.cart-full-help-trigger .help-trigger-icon--svg {
  width: 18px;
  height: 19px;
}

.cart-full-header-item,
.cart-full-body-item {
  padding: 1.5625rem 0 1.5625rem 1.5625rem;
  border-width: 0 0 0.0625rem 0;
  border-bottom: 0.0625rem #e2e3e4 solid;
  box-sizing: border-box;
}

.cart-full-header-item {
  font-weight: bold;
  font-size: 0.875rem;
}

.cart-full-product {
  text-align: left;
}

.cart-full-sku {
  display: none;
  margin-top: 5px;
}

.cart-full-price,
.cart-full-cost {
  text-align: right;
  width: 15%;
}

.cart-full-amount {
  text-align: center;
  width: 15%;
}

.cart-full-remove {
  font-size: 1rem;
  padding-right: 1.5625rem;
  width: 4.75rem;
  text-align: center;
}

.cart-full-photo {
  padding-right: 1.5625rem;
  text-align: center;
}

.cart-full-name-link {
  font-size: 1rem;
}

.cart-full-body .cart-full-price,
.cart-full-body .cart-full-cost {
  font-size: 1rem;
}

.cart-full-properties {
  display: table;
  padding: 0;
  margin: 0.3125rem 0 0 0;
  list-style-type: none;
}

.cart-full-properties-item {
  display: table-row;
}

.cart-full-properties-name,
.cart-full-properties-value {
  display: table-cell;
  vertical-align: middle;
  /* padding-bottom: $padding-base; */
  padding-bottom: 0.2rem;
}

.cart-full-properties-name {
  padding-right: 0.625rem;
}

.cart-full-discount {
  color: #e20a0a;
}

.cart-full-coupon {
  padding-right: 0.625rem;
}

.cart-full-coupon-text {
  line-height: 1.2;
  margin-bottom: 0.9375rem;
}

.checkout-gift-button .btn {
  padding: 0.7188rem 0.625rem;
}

.cart-full-bonus {
  text-align: right;
}

.cart-full-addition {
  padding: 1.25rem 0;
  border-width: 0 0 0.0625rem 0;
  border-bottom: 0.0625rem #e2e3e4 solid;
}

.cart-full-result-block,
.cart-full-remove {
  border-width: 0 0 0.0625rem 0;
}

.cart-full-error,
.cart-amount-error,
.cart-mini-amount-error {
  color: #e20a0a;
}

.cart-full-error,
.cart-amount-error {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.cart-amount-error {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.cart-amount-error.cart-amount-error-mobile {
  position: relative;
  left: auto;
}

.cart-full-error {
  text-align: left;
}

.cart-full-buttons {
  padding-bottom: 1.0625rem;
  text-align: right;
}

.cart-full-empty,
.cart-full-loading {
  text-align: center;
  font-size: 1rem;
  padding: 5rem 0;
}

.cart-amount-error-wrap {
  position: relative;
  line-height: 1;
}

.cart-full-result-name {
  font-size: 0.875rem;
}

.cart-full-result-price {
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1;
}

.cart-full-result-block,
.cart-full-summary-fields {
  padding: 0.625rem;
  text-align: right;
}

.cart-full-result-block {
  padding-right: 0.625rem;
}

.cart-full-summary-name,
.cart-full-summary-price {
  font-size: 0.875rem;
}
.cart-full-summary-name--coupon,
.cart-full-summary-price--coupon {
  display: inline-flex;
  align-items: center;
}
.cart-full-summary-name--coupon .cart-full-help-trigger,
.cart-full-summary-price--coupon .cart-full-help-trigger {
  padding-left: 5px;
  padding-right: 5px;
}

.cart-full-loading-text::before {
  animation: cart-full-loading 2s infinite linear;
}

.cart-full-price-rule {
  font-size: 14px;
  margin: 0.3125rem 0 0 0;
}

/* mobile-cart */
.cart-full-mobile {
  width: 100%;
  box-sizing: border-box;
  display: flex;
}
.cart-full-mobile.panel {
  display: flex;
}
.cart-full-mobile .cart-full-mobile-photo,
.cart-full-mobile .cart-full-mobile-body,
.cart-full-mobile .cart-full-mobile-inputs {
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
}
.cart-full-mobile .cart-full-mobile-photo {
  width: 22%;
  padding-right: 10px;
}
.cart-full-mobile .cart-full-mobile-body {
  width: 78%;
  padding-right: 10px;
}
.cart-full-mobile .cart-full-mobile-body--full-width {
  width: 100%;
  padding-right: 0;
}
.cart-full-mobile .cart-full-mobile-inputs {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}
.cart-full-mobile .cart-full-mobile-name-link {
  font-size: 1rem;
}
.cart-full-mobile .cart-full-mobile-item-cost {
  font-size: 1em;
  font-weight: bold;
  margin-top: 8px;
}
.cart-full-mobile .cart-full-mobile-item-price {
  font-size: 0.875rem;
  text-align: right;
}
.cart-full-mobile .cart-full-mobile-item-reviews {
  font-size: 0.6875rem;
}
.cart-full-mobile .cart-full-mobile-properties-item {
  font-size: 0.75rem;
  list-style: none;
  margin-bottom: 2px;
}
.cart-full-mobile .cart-full-mobile-properties {
  margin: 5px 0;
  padding: 0;
}
.cart-full-mobile .cart-full-mobile-remove {
  font-size: 2.125rem;
}
.cart-full-mobile .cart-full-mobile-price-rule {
  font-size: 0.75rem;
  margin: 0.3125rem 0 0 0;
  text-align: right;
}
.cart-full-mobile__row {
  display: flex;
  margin: 5px 0;
  font-size: 0.875rem;
}

/* .cart-full-mobile-coupon {
    .checkout-gift-button {
        margin: 10px 0;
    }
} */
.cart-full-mobile-coupon-text {
  text-align: center;
  margin: 0 0 10px;
}

.cart-full-mobile-summary-name,
.cart-full-mobile-result-name {
  display: inline-block;
}

.cart-full-mobile-buttons {
  padding: 0.625rem 0;
  text-align: center;
}

.cart-full-mobile-buttons .btn {
  padding: 0 24px;
  line-height: 47px;
  text-transform: uppercase;
}

.cart-full-mobile-result-price {
  font-weight: bold;
  font-size: 1.75rem;
}

@keyframes cart-full-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes cart-full-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes cart-full-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.cart-full-amount-control {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  width: 130px;
}
.cart-full-amount-control--with-unit {
  width: 90px !important;
  margin-right: 20px !important;
  padding-right: 7px;
}
.cart-full-amount-control--with-unit .spinbox-input {
  width: 90px !important;
}
.cart-full-amount-control--with-unit .spinbox__arrows {
  margin: 0;
}

.cart-full-mobile-inputs .spinbox-input {
  min-width: 35px;
}

.cart-full-mobile-inputs .cart-full-mobile-inputs {
  width: 21%;
}

.cart-full-mobile-inputs span {
  /* bottom: 8px; */
  position: relative;
}

.bonus-card-plus-amount {
  padding: 0 0 10px 25px;
}

.bonus-card-plus-amount .bonus-card-plus-price {
  font-weight: bold;
}

.cart-mini-empty {
  text-align: center;
  font-weight: bold;
  padding: 20px;
}

body.swal2-no-backdrop .swal2-shown.swal2-top.mobile-cart-popover-container {
  right: 0;
  top: 41px;
  left: 0;
  transform: none;
}

.mobile-cart-popover {
  transform: none;
  left: 0;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.mobile-cart-popover .swal2-header {
  display: none;
}
.mobile-cart-popover.swal2-popup.swal2-toast,
.mobile-cart-popover .swal2-content {
  width: 100%;
}
.mobile-cart-popover .swal2-content {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.cart-fullcartmessage {
  background-color: #f6d7d7;
}

.order-history-body-item.order-history-body-item-cost {
  position: relative;
  padding-right: 14px;
}
.order-history-body-item.order-history-body-item-cost::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.cart-add--with-spinbox {
  border-radius: 5px;
}

.cart-add__spinbox-block {
  --_cart-add-spinbox-color: var(--cart-add-spinbox-color, #fff);
  --_cart-add-spinbox-br-color: var(--cart-add-spinbox-br-color, var(--_cart-add-spinbox-color));
  --_cart-add-spinbox-br-width: var(--cart-add-spinbox-br-width, 3px);
  --_cart-add-spinbox-width: var(--cart-add-spinbox-width, 28px);
  --_cart-add-spinbox-height: var(--cart-add-spinbox-height, 100%);
  --_cart-add-spinbox-input-bg: var(--cart-add-spinbox-input-bg, #f9f9f9);
  width: 100%;
  position: relative;
}
.cart-add__spinbox-block .spinbox__arrows {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 0;
}
.cart-add__spinbox-block .spinbox-button-disabled {
  opacity: 1;
}
.cart-add__spinbox-block .spinbox-button-disabled::before {
  opacity: 0.5;
}
.cart-add__spinbox-block .spinbox-input-wrap {
  padding: 0;
}
.cart-add__spinbox-block .spinbox-more,
.cart-add__spinbox-block .spinbox-less {
  border-color: var(--_cart-add-spinbox-br-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--_cart-add-spinbox-width);
  height: var(--_cart-add-spinbox-height);
  box-sizing: border-box;
}
.cart-add__spinbox-block .spinbox-more::before,
.cart-add__spinbox-block .spinbox-less::before {
  -webkit-mask-image: none;
          mask-image: none;
  background: none;
  margin-right: 0;
  font-weight: 600;
  font-size: 26px;
  cursor: pointer;
  color: var(--_cart-add-spinbox-color);
}
.cart-add__spinbox-block .spinbox-more {
  border-left-style: solid;
  border-left-width: var(--_cart-add-spinbox-br-width);
}
.cart-add__spinbox-block .spinbox-more::before {
  content: "+";
}
.cart-add__spinbox-block .spinbox-less {
  border-right-style: solid;
  border-right-width: var(--_cart-add-spinbox-br-width);
}
.cart-add__spinbox-block .spinbox-less::before {
  content: "-";
}
.cart-add__spinbox-block .spinbox-block {
  margin: 0;
}

.cart-add__spinbox-input {
  color: var(--_cart-add-spinbox-color);
  background-color: inherit;
  background-color: var(--cart-add-spinbox-input-bg, inherit);
  border: none;
  width: 100%;
  padding-left: var(--_cart-add-spinbox-width);
  padding-right: var(--_cart-add-spinbox-width);
  line-height: 1.3;
  text-align: center;
}
.cart-add__spinbox-input:hover {
  box-shadow: none;
}

.cart-help-trigger-popover.top-right {
  border-bottom-right-radius: 0 !important;
}
.cart-help-trigger-popover.top-right .arrow {
  right: -1px !important;
}