.modal-video-trigger {
  font-size: 18px;
  line-height: 1.56;
  font-weight: 300;
}

/* stylelint-disable */
#modalIframeVideo .modal-content {
  padding: 0;
}

/* stylelint-enable */
.modal-iframe-video-inner {
  width: 100vw;
}

/* .modal-iframe-video-inner {
    width: 100vw;
}

@media (max-width: 30em) {
    .modal-iframe-video-inner {
        min-width: initial;
    }
}

@media (min-width: 30em) {
    .modal-iframe-video-inner {
        width: 70vw;

    }
} */
/* @media (min-width: 48em) {
    .modal-iframe-video-inner {
        width: 70vw;
    }
} */
@media (min-width: 48em) {
  .modal-iframe-video-inner {
    width: 70vw;
    max-width: 1024px;
  }
}
@media (min-height: 600px) and (min-width: 48em) {
  /* stylelint-disable */
  #modalIframeVideo.adv-modal .adv-modal-close,
  #modalIframeVideo.adv-modal-floating .adv-modal-close {
    right: -25px;
    top: -5px;
  }
  /* stylelint-enable */
}