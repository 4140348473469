@charset "UTF-8";
.details-block {
  margin-bottom: 1.875rem;
  border-radius: 0.3125rem;
}

.details-payment {
  border-style: solid;
  border-width: 0.0625rem 0;
  padding: 7px 0;
}
.details-payment .price {
  margin-bottom: 0.3125rem;
  display: block;
}
.details-payment .price-current,
.details-payment .price-new {
  font-size: 1.625rem;
}
.details-payment .price-old {
  font-size: 0.875rem;
}
.details-payment .price-discount {
  font-size: 0.6875rem;
}

.instead__text-price {
  font-size: 16px;
  font-weight: 600;
}

.details-payment-price {
  white-space: nowrap;
}

.details-amount .availability {
  vertical-align: inherit;
}

.details-payment-item {
  padding-left: 0.625rem;
}

.details-payment-inline {
  display: inline-block;
  margin-bottom: 0.3125rem;
  vertical-align: top;
}

.availability {
  display: inline-block;
  vertical-align: middle;
  border-radius: 0.1875rem;
  color: #fff;
  padding: 0.3125rem;
  line-height: 1;
}

div[show-stocks] {
  cursor: pointer;
}

.available {
  background-color: #b1cd03;
}

.not-available {
  background-color: #fb6363;
}

.details-brand {
  text-align: center;
  margin-bottom: 2.5rem;
}

.details-aside {
  /* padding-left: rem-calc(40px); */
}

.details-aside .social-btns {
  padding: 0.9375rem;
}

.details-social {
  margin-bottom: 2.5rem;
}

.details-payment-block {
  display: flex;
  flex-wrap: wrap;
}
.details-payment-block .btn {
  white-space: nowrap;
}

.details-payment-cell {
  margin-top: 7px;
  margin-bottom: 7px;
}

.details-carousel-item,
.details-carousel-item-vertical {
  border: 0.0625rem transparent solid;
  cursor: pointer;
  vertical-align: middle;
  box-sizing: border-box;
  text-align: center;
}

.details-carousel-item {
  display: inline-block;
}

.details-carousel-item-vertical {
  display: block;
}

.details-carousel-selected {
  border-radius: 0.3125rem;
  opacity: 0.5;
  border-color: #efd706;
  cursor: default;
}

.first-payment-note {
  font-size: 0.6875rem;
}

.modal .details-modal-preview {
  /* max-width: 100% !important;
  position: absolute;
  top: rem-calc(45px);
  right: rem-calc(45px);
  bottom: rem-calc(45px);
  left: rem-calc(45px); */
  width: 100%;
  height: calc(100% - 45px - 45px);
  display: inline-flex;
  flex-direction: column;
}
.modal .details-modal-preview .modal-content {
  overflow-y: visible;
  position: relative;
  max-height: 100%;
  flex-grow: 1;
  /* height: 1px; */
  /* чтобы дочерний блок растягивался на всю высоту */
}

.modal-preview-image-load {
  opacity: 0;
}

.modal-preview-image-complete {
  opacity: 1;
  transition: opacity linear 0.5s;
}

.details-buy-one-click {
  line-height: 1;
}

.details-carousel,
.details-tabs {
  margin-top: 0.9375rem;
}

.details-carousel-hidden {
  opacity: 0;
}

.details-preview-block {
  font-size: 0;
  line-height: 0;
}

.details-preview-block .zoomer-window-title {
  line-height: 1;
  font-size: 12px;
}

.details-preview-block .zoomer-inner ~ .zoomer-window .zoomer-window-title {
  top: auto;
  bottom: 0;
}

.details-modal-preview-block {
  /* display: table;
  width: 100%;
  height: 100%; */
  display: flex;
  flex-direction: row;
  height: 100%;
}

.details-modal-preview-cell {
  /* display: table-cell; */
  vertical-align: top;
}

.details-modal-preview-cell-photo {
  width: 100%;
  vertical-align: middle;
  align-self: center;
}

.details-modal-carousel {
  padding: 1.625rem 0;
  height: auto !important;
}

.details-briefdescription p {
  margin: 0;
  padding: 0;
}

.details-spinbox-block {
  width: 5rem;
}

.details-rotate {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  flex-flow: column wrap;
}

.top-shipping-row {
  margin-bottom: 5px;
}

.details-param-name,
.details-param-value,
.details-param-value-weight {
  display: inline;
  font-size: 0.75rem;
}

.details-avalable-text,
.details-avalable-unit {
  display: inline-block;
}

.gifts-list {
  display: none;
}

.gift-properties {
  display: table;
  padding: 0;
  margin: 0.3125rem 0 0;
}

.gift-properties__item {
  display: table-row;
}

.gift-properties__itemName,
.gift-properties__itemValue {
  display: table-cell;
  vertical-align: middle;
  padding-right: 0.625rem;
  font-size: 0.75rem;
}

.product-gift-image {
  height: 69.9px;
  width: 75.9px;
  /* background: url('../../images/misc/giftIcon.png') no-repeat top left/100% 100%; */
  background: url("../../images/misc/giftIcon.png") no-repeat top left/100% 100%;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
}
.product-gift-image .gifts-list-container {
  background: white;
  position: relative;
  border-radius: 0.3125rem;
  z-index: 1;
}
.product-gift-image .gifts-list {
  display: none;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.25);
  width: 300px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -320px;
  white-space: normal;
}
.product-gift-image .gifts-list .gift-txt {
  text-align: left;
}
.product-gift-image .gifts-list .gift-txt-descr {
  font-size: 0.6875rem;
  color: #e20a0a;
}
.product-gift-image .gifts-list::before, .product-gift-image .gifts-list::after {
  content: "";
  display: inline-block;
  left: -20px;
  width: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  position: absolute;
}
.product-gift-image .gifts-list::before {
  background: white;
  height: 20px;
  width: 20px;
  left: -10px;
  transform: rotate(45deg);
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.25);
}
.product-gift-image .gifts-list::after {
  background-color: transparent;
  height: 100%;
}
.product-gift-image.active .gifts-list {
  display: block;
}

.details-modal-preview-arrow {
  width: 100px;
  align-self: center;
  font-size: 40px;
  text-align: center;
}

.details-photos__left {
  margin: 0 10px;
  display: flex;
}

.details-photos__right {
  margin: 0 10px;
}

.details-photos__trigger-360,
.details-photos__trigger-video {
  height: 27px;
  width: 25px;
  padding: 0;
}

.details-photos__trigger + .details-photos__trigger {
  margin-left: 20px;
}

.products-view-video-wrap {
  margin-bottom: 40px;
}
.products-view-video-wrap:last-child {
  margin-bottom: 0;
}
.products-view-video-wrap .video-name,
.products-view-video-wrap .video-description {
  font-size: 14px;
  line-height: 1.5;
}
.products-view-video-wrap .video-name {
  margin-bottom: 10px;
  font-size: 22px;
}
.products-view-video-wrap .video-description {
  margin-top: 10px;
  white-space: normal;
}

.product-videos-in-modal {
  display: flex;
}
.product-videos-in-modal .products-view-video-wrap {
  margin-bottom: 0;
}

.carousel-product-video-in-modal:not(.carousel-nav-not-show) {
  /* padding: 0 45px; */
}

.carousel-product-video-in-modal-btn {
  font-size: 36px;
}

.carousel-product-video-in-modal-content {
  width: 650px;
}

.cart-popup-product-sku {
  font-size: 13px;
}

.related-product-carousel-wrap {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.details-row {
  margin-top: 0.9375rem;
}
.details-row__amount-table {
  margin-top: 20px;
  margin-bottom: 20px;
}

.product-card--quickview .details-size-color-picker--cols-1 .color-select-custom,
.product-card--quickview .details-size-color-picker--cols-1 .sizes-select-custom {
  width: 50%;
}

.details-size-color-picker--cols-1 .color-select-custom,
.details-size-color-picker--cols-1 .sizes-select-custom {
  width: 60%;
}

.details-size-color-picker--cols-2 {
  display: flex;
  flex-wrap: nowrap;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.details-size-color-picker--cols-2 .details-colors,
.details-size-color-picker--cols-2 .details-sizes {
  flex-grow: 1;
  min-width: calc(50% - 5px);
}
.details-size-color-picker--cols-2 .sizes-viewer-list {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.details-size-color-picker--cols-2 .sizes-viewer-list [data-sizes-viewer] {
  flex-grow: 1;
}
.details-size-color-picker--cols-2 .sizes-viewer-list .select-custom,
.details-size-color-picker--cols-2 .sizes-viewer-list .choices,
.details-size-color-picker--cols-2 .sizes-viewer-list .form-select {
  height: 100%;
}
.details-size-color-picker--cols-2 .sizes-viewer-list .form-select {
  display: flex;
  align-items: center;
}

.marketplace-button-header {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #000;
  margin-bottom: 10px;
}

.marketplace-buttons {
  height: 50px;
  gap: 20px;
}

.product-preview-modal-wrap .details-modal-preview {
  max-width: 100vw !important;
  height: 100% !important;
  border-radius: 0 !important;
}
.product-preview-modal-wrap .details-modal-preview .modal-header {
  display: none;
}
.product-preview-modal-wrap .details-modal-preview .adv-modal-close {
  top: 15px !important;
  overflow: visible;
  color: black !important;
}
.product-preview-modal-wrap .details-modal-preview .adv-modal-close::after {
  content: "\e829" !important;
  right: 50px !important;
  top: -13px;
  background: none !important;
}
.product-preview-modal-wrap .details-modal-preview-cell {
  align-self: center !important;
}
.product-preview-modal-wrap .details-modal-carousel {
  padding-top: 2rem;
  margin-top: 2rem;
}