.lp-shipping-variants {
  border-radius: 8px;
  border: solid 1px #f1f1f1;
}

.lp-shipping-variants__header {
  margin: 0;
  padding: 8px;
  border-bottom: solid 1px #f1f1f1;
}

.shipping-variants,
.shipping-variants-link-show,
.shipping-variants-other-state {
  padding: 8px;
}