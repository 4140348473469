.font-size-8--adaptive {
  font-size: 8px;
}

.font-size-10--adaptive {
  font-size: 10px;
}

.font-size-12--adaptive {
  font-size: 12px;
}

.font-size-14--adaptive {
  font-size: 12px;
}
@media screen and (min-width: 320px) {
  .font-size-14--adaptive {
    font-size: calc(0.2272727273vw + 11.2727272727px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-14--adaptive {
    font-size: 14px;
  }
}

.font-size-16--adaptive {
  font-size: 14px;
}
@media screen and (min-width: 320px) {
  .font-size-16--adaptive {
    font-size: calc(0.2272727273vw + 13.2727272727px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-16--adaptive {
    font-size: 16px;
  }
}

.font-size-18--adaptive {
  font-size: 15px;
}
@media screen and (min-width: 320px) {
  .font-size-18--adaptive {
    font-size: calc(0.3409090909vw + 13.9090909091px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-18--adaptive {
    font-size: 18px;
  }
}

.font-size-20--adaptive {
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  .font-size-20--adaptive {
    font-size: calc(0.4545454545vw + 14.5454545455px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-20--adaptive {
    font-size: 20px;
  }
}

.font-size-22--adaptive {
  font-size: 17px;
}
@media screen and (min-width: 320px) {
  .font-size-22--adaptive {
    font-size: calc(0.5681818182vw + 15.1818181818px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-22--adaptive {
    font-size: 22px;
  }
}

.font-size-24--adaptive {
  font-size: 18px;
}
@media screen and (min-width: 320px) {
  .font-size-24--adaptive {
    font-size: calc(0.6818181818vw + 15.8181818182px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-24--adaptive {
    font-size: 24px;
  }
}

.font-size-26--adaptive {
  font-size: 19px;
}
@media screen and (min-width: 320px) {
  .font-size-26--adaptive {
    font-size: calc(0.7954545455vw + 16.4545454545px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-26--adaptive {
    font-size: 26px;
  }
}

.font-size-28--adaptive {
  font-size: 20px;
}
@media screen and (min-width: 320px) {
  .font-size-28--adaptive {
    font-size: calc(0.9090909091vw + 17.0909090909px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-28--adaptive {
    font-size: 28px;
  }
}

.font-size-30--adaptive {
  font-size: 21px;
}
@media screen and (min-width: 320px) {
  .font-size-30--adaptive {
    font-size: calc(1.0227272727vw + 17.7272727273px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-30--adaptive {
    font-size: 30px;
  }
}

.font-size-32--adaptive {
  font-size: 22px;
}
@media screen and (min-width: 320px) {
  .font-size-32--adaptive {
    font-size: calc(1.1363636364vw + 18.3636363636px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-32--adaptive {
    font-size: 32px;
  }
}

.font-size-34--adaptive {
  font-size: 23px;
}
@media screen and (min-width: 320px) {
  .font-size-34--adaptive {
    font-size: calc(1.25vw + 19px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-34--adaptive {
    font-size: 34px;
  }
}

.font-size-36--adaptive {
  font-size: 24px;
}
@media screen and (min-width: 320px) {
  .font-size-36--adaptive {
    font-size: calc(1.3636363636vw + 19.6363636364px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-36--adaptive {
    font-size: 36px;
  }
}

.font-size-38--adaptive {
  font-size: 25px;
}
@media screen and (min-width: 320px) {
  .font-size-38--adaptive {
    font-size: calc(1.4772727273vw + 20.2727272727px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-38--adaptive {
    font-size: 38px;
  }
}

.font-size-40--adaptive {
  font-size: 26px;
}
@media screen and (min-width: 320px) {
  .font-size-40--adaptive {
    font-size: calc(1.5909090909vw + 20.9090909091px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-40--adaptive {
    font-size: 40px;
  }
}

.font-size-42--adaptive {
  font-size: 27px;
}
@media screen and (min-width: 320px) {
  .font-size-42--adaptive {
    font-size: calc(1.7045454545vw + 21.5454545455px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-42--adaptive {
    font-size: 42px;
  }
}

.font-size-44--adaptive {
  font-size: 28px;
}
@media screen and (min-width: 320px) {
  .font-size-44--adaptive {
    font-size: calc(1.8181818182vw + 22.1818181818px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-44--adaptive {
    font-size: 44px;
  }
}

.font-size-46--adaptive {
  font-size: 29px;
}
@media screen and (min-width: 320px) {
  .font-size-46--adaptive {
    font-size: calc(1.9318181818vw + 22.8181818182px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-46--adaptive {
    font-size: 46px;
  }
}

.font-size-48--adaptive {
  font-size: 30px;
}
@media screen and (min-width: 320px) {
  .font-size-48--adaptive {
    font-size: calc(2.0454545455vw + 23.4545454545px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-48--adaptive {
    font-size: 48px;
  }
}

.font-size-50--adaptive {
  font-size: 31px;
}
@media screen and (min-width: 320px) {
  .font-size-50--adaptive {
    font-size: calc(2.1590909091vw + 24.0909090909px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-50--adaptive {
    font-size: 50px;
  }
}

.font-size-52--adaptive {
  font-size: 32px;
}
@media screen and (min-width: 320px) {
  .font-size-52--adaptive {
    font-size: calc(2.2727272727vw + 24.7272727273px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-52--adaptive {
    font-size: 52px;
  }
}

.font-size-62--adaptive {
  font-size: 33px;
}
@media screen and (min-width: 320px) {
  .font-size-62--adaptive {
    font-size: calc(3.2954545455vw + 22.4545454545px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-62--adaptive {
    font-size: 62px;
  }
}

.font-size-72--adaptive {
  font-size: 34px;
}
@media screen and (min-width: 320px) {
  .font-size-72--adaptive {
    font-size: calc(4.3181818182vw + 20.1818181818px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-72--adaptive {
    font-size: 72px;
  }
}

.font-size-82--adaptive {
  font-size: 35px;
}
@media screen and (min-width: 320px) {
  .font-size-82--adaptive {
    font-size: calc(5.3409090909vw + 17.9090909091px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-82--adaptive {
    font-size: 82px;
  }
}

.font-size-92--adaptive {
  font-size: 36px;
}
@media screen and (min-width: 320px) {
  .font-size-92--adaptive {
    font-size: calc(6.3636363636vw + 15.6363636364px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-92--adaptive {
    font-size: 92px;
  }
}

.font-size-102--adaptive {
  font-size: 37px;
}
@media screen and (min-width: 320px) {
  .font-size-102--adaptive {
    font-size: calc(7.3863636364vw + 13.3636363636px);
  }
}
@media screen and (min-width: 1200px) {
  .font-size-102--adaptive {
    font-size: 102px;
  }
}