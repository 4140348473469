.readmore-content {
  overflow: hidden;
  transition-property: max-height;
  transition-timing-function: linear;
  transition-delay: 0;
  position: relative;
}

.readmore-expanded .readmore-content {
  max-height: 2000px !important;
}

.readmore-link {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.readmore-link:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.readmore-unvisible {
  position: absolute;
  visibility: hidden;
}