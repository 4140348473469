.sizes-viewer-block {
  display: inline-block;
  text-align: center;
}

.sizes-viewer-item,
.sizes-viewer-inner {
  border-radius: 0.1875rem;
  position: relative;
  cursor: pointer;
}

.sizes-viewer-item {
  border: 1px transparent solid;
  display: inline-block;
}

.sizes-viewer-inner {
  margin: 0.25rem;
  line-height: 1;
  padding: 0.3125rem;
  font-size: 0.875rem;
}

.sizes-viewer-selector {
  font-size: 0;
  line-height: 0;
}

.sizes-viewer-radio {
  border: 0;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  position: absolute;
}

.sizes-viewer-block-slider {
  padding: 0 20px;
}

.sizes-viewer-header {
  font-size: 0.75rem;
  margin-bottom: 0.3125rem;
}

.sizes-viewer-item-disabled {
  opacity: 0.5;
  cursor: default;
}