.adv-modal-floating .modal-product-video,
.adv-modal .modal-product-video {
  min-width: 750px;
  min-height: 470px;
  max-width: 1000px;
  max-height: 700px;
}
.adv-modal-floating .modal-product-video .modal-content,
.adv-modal .modal-product-video .modal-content {
  box-sizing: border-box;
  width: 100%;
}
.adv-modal-floating .modal-product-video .carousel-product-video-in-modal--wrap,
.adv-modal .modal-product-video .carousel-product-video-in-modal--wrap {
  flex-grow: 1;
}
.adv-modal-floating .modal-product-video .products-view-video-wrap,
.adv-modal .modal-product-video .products-view-video-wrap {
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
}
.adv-modal-floating .modal-product-video .carousel-inner,
.adv-modal .modal-product-video .carousel-inner {
  margin: 0 auto;
}
.adv-modal-floating .modal-product-video .video-embed,
.adv-modal .modal-product-video .video-embed {
  margin-top: 15px;
}

@media (max-width: 750px) {
  .modal-product-video {
    min-width: 480px;
    min-height: 310px;
  }
}
@media (max-width: 895px) {
  .modal-product-video {
    width: 100%;
  }
}
/* product video */
.video-embed {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
}
.video-embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-embed .embed-container {
  position: static;
  padding-bottom: 0;
}