.rating {
  display: inline-block;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 1.125rem;
  line-height: 1;
  direction: rtl;
}

.rating-item {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: #d1d1d1;
  line-height: 1;
}

.rating-readonly .rating-item {
  cursor: default;
}

.rating-item-selected {
  color: #fec419;
}

/* .rating-item-hover {
    color: #db2929;
} */
.rating:not(.rating-readonly) > li:hover::before,
.rating:not(.rating-readonly) > li:hover ~ li::before {
  color: #db2929;
}