help-trigger {
  display: inline-block;
  vertical-align: middle;
}

.help-trigger__container {
  line-height: 1;
}

.help-trigger-icon-wrap {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.help-trigger-icon {
  color: #676a6c;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  vertical-align: middle;
  font-size: 20px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.help-trigger-icon-abs {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.help-trigger-icon--svg {
  display: block;
  font-size: inherit;
  height: 100%;
  overflow: visible;
  width: 0.75em;
}
.help-trigger-icon--svg-info {
  width: 1.1em;
}

@media (min-width: 768px) {
  .help-trigger-icon {
    padding: 3px;
  }
}