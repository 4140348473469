@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.adv-modal .modal-header,
.adv-modal-floating .modal-header {
  font-size: 16px;
  line-height: 1.25;
  font-weight: normal;
  padding: 16px 45px 12px 25px;
  border-bottom-color: #f0f0f0;
}
.adv-modal .modal-content,
.adv-modal-floating .modal-content {
  overflow-x: hidden;
  overflow-y: auto;
  overflow: hidden auto;
  padding: 16px;
  box-shadow: none;
  max-height: calc(var(--min-full-height) - 45px);
  line-height: normal;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  background: #f9f9f9;
}
.adv-modal .modal-content::-webkit-scrollbar-track,
.adv-modal-floating .modal-content::-webkit-scrollbar-track {
  background-color: transparent;
}
.adv-modal .modal-header ~ .modal-content,
.adv-modal-floating .modal-header ~ .modal-content {
  max-height: calc(var(--min-full-height) - 45px - 49px);
}
.adv-modal .modal-footer ~ .modal-content,
.adv-modal-floating .modal-footer ~ .modal-content {
  max-height: calc(var(--min-full-height) - 45px - 73px);
}
.adv-modal .modal-header ~ .modal-footer ~ .modal-content,
.adv-modal-floating .modal-header ~ .modal-footer ~ .modal-content {
  max-height: calc(var(--min-full-height) - 45px - 49px - 73px);
}
.adv-modal .modal-footer,
.adv-modal-floating .modal-footer {
  background-color: transparent;
  border-top-color: #f4f5f7;
  padding-left: 23px;
  padding-right: 23px;
  text-align: left;
  order: 100;
  line-height: 1 !important;
}
.adv-modal .adv-modal-inner,
.adv-modal-floating .adv-modal-inner {
  display: inline-flex;
  border-width: 1px;
  border-style: solid;
}
.adv-modal .adv-modal-close::after,
.adv-modal-floating .adv-modal-close::after {
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4gICAgPHBhdGggZmlsbD0iI2MxYzFjMSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTMuMTMyIDEyLjQyNWw2LjcxNyA2LjcxNy0uNzA3LjcwNy02LjcxNy02LjcxNy02LjcxOCA2LjcxN0w1IDE5LjE0Mmw2LjcxOC02LjcxN0w1IDUuNzA3IDUuNzA3IDVsNi43MTggNi43MThMMTkuMTQyIDVsLjcwNy43MDctNi43MTcgNi43MTh6Ii8+PC9zdmc+") !important;
  height: 24px;
  width: 24px;
  font-size: 0;
  line-height: 0;
}

.adv-modal-inner {
  border-color: transparent;
}

.lp-settings-modal-overlay .modal-content,
.blocks-constructor-modal--settings .modal-content {
  max-height: 90vh;
}
.lp-settings-modal-overlay .modal-header ~ .modal-content,
.blocks-constructor-modal--settings .modal-header ~ .modal-content {
  max-height: calc(90vh - 49px);
}
.lp-settings-modal-overlay .modal-footer ~ .modal-content,
.blocks-constructor-modal--settings .modal-footer ~ .modal-content {
  max-height: calc(90vh - 73px);
}
.lp-settings-modal-overlay .modal-header ~ .modal-footer ~ .modal-content,
.blocks-constructor-modal--settings .modal-header ~ .modal-footer ~ .modal-content {
  max-height: calc(90vh - 49px - 73px);
}

.adv-modals--hide {
  overflow: auto !important;
}
.adv-modals--hide .adv-modal,
.adv-modals--hide .adv-modal-floating {
  display: none !important;
}

@media (min-width: 48em) and (min-height: 600px) {
  .adv-modal .adv-modal-close,
  .adv-modal-floating .adv-modal-close {
    left: auto;
    right: 12px;
    top: 12px;
    box-sizing: border-box;
    border-style: solid;
    font-size: 0;
    height: 24px;
    width: 24px;
    line-height: normal;
  }
  .adv-modal .adv-modal-close::after,
  .adv-modal-floating .adv-modal-close::after {
    content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4gICAgPHBhdGggZmlsbD0iI2MxYzFjMSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTMuMTMyIDEyLjQyNWw2LjcxNyA2LjcxNy0uNzA3LjcwNy02LjcxNy02LjcxNy02LjcxOCA2LjcxN0w1IDE5LjE0Mmw2LjcxOC02LjcxN0w1IDUuNzA3IDUuNzA3IDVsNi43MTggNi43MThMMTkuMTQyIDVsLjcwNy43MDctNi43MTcgNi43MTh6Ii8+PC9zdmc+") !important;
    height: 24px;
    width: 24px;
    font-size: 0;
    line-height: 0;
  }
  .adv-modal .adv-modal-close + .modal-header,
  .adv-modal-floating .adv-modal-close + .modal-header {
    padding-right: 50px;
  }
  .adv-modal .adv-modal-inner,
  .adv-modal-floating .adv-modal-inner {
    height: auto;
  }
  .adv-modal .modal-content,
  .adv-modal-floating .modal-content {
    max-height: 100vh;
  }
}
.modal-quickview {
  min-height: 0;
}

@media (min-width: 48em) {
  .adv-modal .modal-content,
  .adv-modal-floating .modal-content {
    padding: 25px;
    background: transparent;
  }
  .adv-modal .modal-content::-webkit-scrollbar-track,
  .adv-modal-floating .modal-content::-webkit-scrollbar-track {
    background-color: #fff;
  }
}