.harmonica {
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.harmonica-initialized {
  overflow: visible;
}
.harmonica-initialized .harmonica-tile {
  opacity: 1;
}

.harmonica-tile {
  cursor: pointer;
  list-style: none;
  position: relative;
  opacity: 0;
}

.harmonica-tile-dots {
  z-index: 20;
  position: relative;
}

.harmonica-tile-list {
  border-radius: 0.3125rem;
  background: #fff;
  position: absolute;
  z-index: 15;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.25);
  top: 0;
  opacity: 0;
  left: auto;
  right: 0;
  text-align: right;
}

/* .harmonica-tile-submenu-invert {
    left: auto;
    right: 0;
    text-align: right;
} */
.harmonica-tile-row {
  margin: 0.4375rem 0;
}

.harmonica-tile-submenu-active {
  opacity: 1;
}

.harmonica-tile-link {
  line-height: 1.2;
}

.harmonica-tile-inner {
  display: inline-block;
}