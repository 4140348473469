.reviews-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.reviews-list .reviews-list {
  padding: 0.625rem 0 1.0625rem 1.25rem;
}

.review-item-name,
.review-item-date {
  padding-right: 0.9375rem;
  line-height: 1;
  vertical-align: middle;
}

.review-item-image {
  padding: 0 10px 10px 0;
}

.review-item-name {
  font-size: 0.8125rem;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  padding-right: 0.9375rem;
  gap: 5px;
}

.review-item-date {
  font-size: 0.6875rem;
  padding-left: 0.9375rem;
  border-left: 0.0625rem #e5e5e5 solid;
}

.is-mobile .review-item-date {
  flex-grow: 1;
}

.review-item {
  /* margin-bottom: rem-calc(40px); */
  margin-bottom: 20px;
}
.review-item .reviews-list {
  margin-bottom: 0;
  padding-bottom: 0;
}
.review-item .review-item:last-child {
  margin-bottom: 0;
}
.review-item .review-form-block {
  margin-top: 1.0625rem;
}

.review-form-header {
  font-size: 1rem;
  margin-bottom: 1.5625rem;
  line-height: 1;
}

.review-shift-right {
  padding-right: 0.1875rem;
}

.review-shift-left {
  padding-left: 0.1875rem;
}

.review-item-button {
  margin-right: 1.0625rem;
}

.review-item-text {
  padding: 1.25rem 0 0;
  display: block;
}

.review-photos {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-left: -6px;
  margin-right: -6px;
}

.review-photos__item {
  flex-basis: 80px;
  max-width: 80px;
  height: 80px;
  padding: 6px;
  position: relative;
}

.review-photos__delete {
  border-radius: 50px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
  border: 1px solid currentcolor;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.review-photos__wrap-thumbs {
  display: inline-flex;
  border: 1px solid;
  font-size: 0;
  border-radius: 0.3125rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 6px;
  box-sizing: border-box;
}

.reviews-captcha {
  margin: 0;
}

.review-item-buttons {
  margin-top: 20px;
}

.review-item-rating {
  display: inline-block;
  vertical-align: middle;
  margin-left: 40px;
}

.review-item-rating__item {
  display: inline-block;
  vertical-align: middle;
}

.review-item-rating__count--likes {
  color: #298800;
}

.review-item-rating__count--dislikes {
  color: #f81f1f;
}

.review-item__photo-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  margin: 20px -6px 0;
  padding: 0;
}

.review-item__photo-item {
  padding: 6px;
  margin: 0;
}

.review-item__head {
  display: flex;
  align-items: center;
}

.review-item__product-rating {
  white-space: nowrap;
  margin-top: 10px;
}