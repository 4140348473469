/* stylelint-disable */
/* stylelint-enable */
.buy-one-click-dialog,
.adv-modal .adv-modal-inner.buy-one-click-dialog,
.adv-modal-floating .adv-modal-inner.buy-one-click-dialog {
  min-width: 35vw;
  max-width: 35vw;
}

@media (min-device-width : 768px) and (max-device-width : 1024px) {
  .buy-one-click-dialog {
    min-width: 70vw;
  }
}
.buy-one-click-buttons {
  text-align: right;
  margin-top: 0.625rem;
}