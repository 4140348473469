@charset "UTF-8";
.quickview-arrows-prev,
.quickview-arrows-next {
  font-size: 2.5rem;
  position: absolute;
  background: none;
  border: 0;
  box-sizing: border-box;
  width: 3.75rem;
  display: flex;
  top: -43px;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  justify-content: center;
}
.quickview-arrows-prev:focus,
.quickview-arrows-next:focus {
  outline: none;
}
.quickview-arrows-prev::before,
.quickview-arrows-next::before {
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  margin: 0;
}

.quickview-arrows-prev {
  margin-left: -50px;
}

.quickview-arrows-next {
  margin-left: 50px;
}

@media (min-width: 1180px) {
  .quickview-arrows-prev,
  .quickview-arrows-next {
    font-size: 3.125rem;
    top: 50%;
    left: auto;
    transform: translateY(-50%);
  }
  .quickview-arrows-next {
    left: 100%;
    margin-left: 1.0625rem;
  }
  .quickview-arrows-prev {
    right: 100%;
    margin-left: auto;
    margin-right: 1.0625rem;
  }
}
.adv-modal .modal-quickview,
.adv-modal .adv-modal-inner.modal-quickview {
  max-width: 64.125rem;
  width: 100%;
  height: auto; /* не подгружает контент в айфоне поэтому auto */
}

.modal-quickview {
  min-height: 540px;
}
.modal-quickview .details-availability,
.modal-quickview .details-rating {
  display: inline-block;
  vertical-align: middle;
}
.modal-quickview .details-rating {
  margin-left: 1.0625rem;
}

.quickview-loading-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (min-device-width: 768px) and (max-device-width: 1024px) and (min-height: 768px) {
  .adv-modal .modal-quickview {
    max-width: 51rem;
    min-width: 51rem;
  }
}