.choices,
.choices__list--dropdown .choices__item,
.choices__list[aria-expanded] .choices__item {
  font-size: inherit;
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
  border-radius: 5px;
  min-width: 100%;
  width: auto;
}

.choices__list--single {
  padding: 0;
  display: inline-flex;
}

.choices[data-type*=select-one]::after {
  display: none;
}

.choices__list--dropdown {
  color: #000;
}

ng-choice-template {
  display: block;
}

@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable,
  .choices__list[aria-expanded] .choices__item--selectable {
    padding-right: 15px;
  }
}
.choices__list--dropdown .choices__item,
.choices__list[aria-expanded] .choices__item {
  padding-left: 15px;
  padding-right: 15px;
}

.choices__item--selectable,
.choices__item--choice {
  white-space: nowrap;
}

.choices__item--selectable {
  text-overflow: ellipsis;
  overflow: hidden;
}

.choices-container--mobile.is-open::before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.5);
}
.choices-container--mobile .is-active.choices__list--dropdown,
.choices-container--mobile .is-active.choices__list[aria-expanded] {
  position: fixed;
  top: 50%;
  right: 16px;
  bottom: auto;
  left: 16px;
  transform: translateY(-50%);
  z-index: 600;
  min-width: auto;
  max-width: 90vw;
  max-height: 70vh;
  max-height: 90dvh;
}

.choices__list {
  overscroll-behavior: contain;
}