.custom-options-list {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.custom-options-row {
  display: table-row;
}
.custom-options-name, .custom-options-value {
  display: table-cell;
  vertical-align: middle;
}
.custom-options-name {
  padding: 0.3125rem 0.3125rem 0.3125rem 0;
}
.custom-options-value {
  padding: 0.3125rem 0 0.3125rem 0.3125rem;
}
.custom-options--additional-goods .custom-options-list {
  display: grid !important;
  grid-row-gap: 20px;
  row-gap: 20px;
}
.custom-options--additional-goods .custom-options-name,
.custom-options--additional-goods .custom-options-list,
.custom-options--additional-goods .custom-options-value,
.custom-options--additional-goods .custom-options-row {
  display: block;
}
.custom-options--additional-goods .custom-options-name {
  padding: 0;
  display: grid;
  grid-row-gap: 10px;
  row-gap: 10px;
}
.custom-options--additional-goods .custom-options-name > label {
  font-weight: 600;
  font-size: 28px;
  line-height: 1.1 !important;
}
.custom-options--additional-goods .custom-options-value {
  padding: 0;
}
.custom-options--additional-goods .custom-options-inner {
  display: grid;
  grid-row-gap: 10px;
  row-gap: 10px;
}
.custom-options--additional-goods .custom-options-control {
  display: flex;
  align-items: center;
  -moz-column-gap: 16px;
       column-gap: 16px;
  padding: 16px 16px 16px 20px;
  border-radius: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background: #fff;
}
.custom-options--additional-goods .custom-options-control .adv-checkbox-emul,
.custom-options--additional-goods .custom-options-control .adv-radio-emul {
  margin-right: 0;
}
.custom-options--additional-goods .custom-options-control .custom-input-native:focus ~ .custom-input-checkbox {
  box-shadow: none !important;
}
.custom-options--additional-goods .custom-options-control .custom-input-checkbox:hover,
.custom-options--additional-goods .custom-options-control .custom-input-radio:hover {
  box-shadow: none !important;
}
.custom-options--additional-goods .custom-options-control--pointer {
  cursor: pointer;
}
.custom-options--additional-goods .custom-options-control-info {
  display: grid;
  grid-row-gap: 12px;
  row-gap: 12px;
  flex-grow: 1;
}
.custom-options--additional-goods .custom-options-img-wrap {
  max-width: 70px;
}
.custom-options--additional-goods .custom-options-img-wrap > img {
  display: block;
}
.custom-options--additional-goods .custom-options-text-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
.custom-options--additional-goods .custom-options-text {
  display: grid;
  grid-row-gap: 6px;
  row-gap: 6px;
  padding-top: 0 !important;
}
.custom-options--additional-goods .custom-options-price {
  font-size: 14px;
  font-weight: bolder;
  line-height: 1.1;
}
.custom-options--additional-goods .custom-options-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.1;
}
.custom-options--additional-goods .custom-options-item-description {
  font-size: 14px;
  line-height: 1.2;
}
.custom-options--additional-goods .custom-options-item-counter {
  width: 110px;
  cursor: auto;
}
.custom-options--additional-goods .custom-options-item-counter .spinbox-input {
  pointer-events: none !important;
}
.custom-options--additional-goods .custom-options-item-counter .spinbox-button-disabled {
  opacity: 0.5;
}