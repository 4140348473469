.warehouses-list-map {
  --height-map: 630px;
}
.warehouses-list-map--compact-mode .warehouses-list-map__row--nowrap {
  flex-wrap: nowrap;
}
.warehouses-list-map__filters {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  grid-auto-flow: column;
  gap: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.warehouses-list-map__filter-item {
  display: flex;
  align-items: center;
}
.warehouses-list-map__spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.warehouses-list-map__map {
  height: var(--height-map);
}