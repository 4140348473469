.cart-stock-in-warehouses {
  --out-stock-color: #d41212;
}
.cart-stock-in-warehouses__out-stock-link {
  font-size: 14px;
  line-height: 1.8;
  color: #f00;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.cart-stock-in-warehouses__out-stock-link:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}
.cart-stock-in-warehouses__list {
  margin-top: 13px;
}

.cart-stock-list {
  display: grid;
  grid-gap: 10px;
  gap: 10px;
}
.cart-stock-list__header {
  font-size: 13px;
  line-height: 1.2;
  font-weight: bold;
}
.cart-stock-list__row {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
}
.cart-stock-list__body {
  display: grid;
  grid-gap: 10px;
  gap: 10px;
}
.cart-stock-list__data {
  font-size: 13px;
}
.cart-stock-list__data--out-stock {
  color: var(--out-stock-color);
}

@media (max-width: 480px) {
  .cart-stock-list__header {
    font-size: 13px;
  }
}