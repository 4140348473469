.threesixty {
  margin: 0 auto;
  overflow: hidden;
  max-height: 100%;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: auto;
}

.threesixty .threesixty_images {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.threesixty .threesixty_images img {
  top: 0;
  width: 100%;
  height: auto;
}

.threesixty .threesixty_images img.previous-image {
  display: none;
  /* stylelint-disable */
  /*
             Do not put here any other styles.
             It causes massive recalc / repaints and image flickering,
             especially on Firefox.
          */
  /* stylelint-enable */
}

.threesixty .threesixty_images img.current-image {
  display: block;
  /* stylelint-disable */
  /*
             Do not put here any other styles.
             It causes massive recalc / repaints and image flickering,
             especially on Firefox.
          */
  /* stylelint-enable */
}

.threesixty .spinner {
  width: 60px;
  display: block;
  margin: 0 auto;
  height: 30px;
  background: #333;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
}

.threesixty .spinner span {
  font-family: Arial, "MS Trebuchet", sans-serif;
  font-size: 12px;
  font-weight: bolder;
  color: #fff;
  text-align: center;
  line-height: 30px;
  display: block;
}

.threesixty .nav_bar {
  position: absolute;
  color: #fff;
  z-index: 1;
  height: 32px;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.5);
}

.threesixty .nav_bar.bottom-right {
  bottom: 10px;
  right: 20px;
}

.threesixty .nav_bar.bottom-left {
  bottom: 10px;
  left: 20px;
}

.threesixty .nav_bar.top-left {
  top: 10px;
  left: 20px;
}

.threesixty .nav_bar.top-right {
  top: 10px;
  right: 20px;
}

.threesixty .nav_bar.top-center {
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.threesixty .nav_bar.bottom-center {
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.threesixty .nav_bar .butn {
  z-index: 2;
  float: left;
  width: 30px;
  height: 30px;
  padding: 7.5px;
  border-right: 1px solid rgba(0, 0, 0, 0.6);
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0;
  cursor: pointer;
}

.threesixty .nav_bar .butn:first-child {
  border-left: none;
}

.threesixty .nav_bar .butn:last-child {
  border-right: none;
}

.threesixty .nav_bar .butn span {
  z-index: 13;
  display: block;
  width: 15px;
  height: 15px;
  margin: auto;
}

.threesixty .nav_bar .butn span.icon-play {
  background: url("../images/sprite.png") no-repeat 0 0;
}

.threesixty .nav_bar .butn span.icon-pause {
  background: url("../images/sprite.png") no-repeat -15px 0;
}

.threesixty .nav_bar .butn span.icon-forward {
  background: url("../images/sprite.png") no-repeat -30px 0;
}

.threesixty .nav_bar .butn span.icon-back {
  background: url("../images/sprite.png") no-repeat -45px 0;
}

.threesixty .nav_bar .butn span.icon-fullscreen {
  background: url("../images/sprite.png") no-repeat -60px 0;
}

.threesixty .nav_bar .butn span.icon-resize {
  background: url("../images/sprite.png") no-repeat -75px 0;
}

.threesixty .nav_bar .butn span.icon-download {
  background: url("../images/sprite.png") no-repeat -90px 0;
}

/* stylelint-disable */
_:-webkit-full-screen,
.threesixty {
  background-color: transparent;
}

/* stylelint-enable */
.threesixty_images {
  cursor: e-resize;
}