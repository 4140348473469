.carousel {
  position: relative;
  box-sizing: border-box;
  transform: translate3d(0, 0, 0);
}

.carousel-main-list {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.carousel-inner {
  overflow: hidden;
  position: relative;
}

.carousel-list {
  white-space: nowrap;
  font-size: 0;
  line-height: 0;
  transition-property: transform;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}

.carousel-item {
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  transform: translateZ(0) scale(1, 1);
}

.carousel-dots {
  padding: 0;
  margin: 0;
  list-style: none;
  display: block;
  text-align: center;
  margin-top: 10px;
  line-height: 1;
}

.carousel-dots-item {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 0.3125rem;
  font-size: 0;
  line-height: 0;
  opacity: 0.5;
}

.carousel-dots-item-inner {
  border-radius: 50%;
  height: 0.5rem;
  width: 0.5rem;
  display: inline-block;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
}

.carousel-dots-selected {
  opacity: 1;
}

.carousel-nav-prev,
.carousel-nav-next {
  position: absolute;
  text-align: center;
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent none;
  font-size: 1rem;
  cursor: pointer;
}
.carousel-nav-prev:focus,
.carousel-nav-next:focus {
  outline: none;
}
.carousel-nav-prev::after,
.carousel-nav-next::after {
  margin: 0;
}
.carousel-nav-prev:disabled,
.carousel-nav-next:disabled {
  display: none;
}

.carousel-vertical.carousel-wrap-nav-outside {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.carousel-vertical > .carousel-nav-inside .carousel-nav-prev {
  top: 0;
  transform: translateX(-50%);
  left: 50%;
}
.carousel-vertical > .carousel-nav-inside .carousel-nav-next {
  bottom: 0;
  transform: translateX(-50%);
  left: 50%;
}
.carousel-vertical > .carousel-nav-outside .carousel-nav-prev {
  bottom: 100%;
  transform: translateX(-50%);
  left: 50%;
}
.carousel-vertical > .carousel-nav-outside .carousel-nav-next {
  top: 100%;
  transform: translateX(-50%);
  left: 50%;
}

.carousel-horizontal.carousel-wrap-nav-outside {
  margin-top: 2rem;
}
.carousel-horizontal .carousel-nav-prev {
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.carousel-horizontal .carousel-nav-next {
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.carousel-horizontal .carousel-nav-inside .carousel-nav-prev {
  left: 0;
}
.carousel-horizontal .carousel-nav-inside .carousel-nav-next {
  right: 0;
}
.carousel-horizontal .carousel-nav-outside {
  position: absolute;
  bottom: 100%;
  right: 0.625rem;
  white-space: nowrap;
}
.carousel-horizontal .carousel-nav-outside .carousel-nav-prev,
.carousel-horizontal .carousel-nav-outside .carousel-nav-next {
  position: relative;
  width: 35px;
  left: auto;
  right: auto;
  top: auto;
  transform: translateY(0);
  display: inline-block;
  vertical-align: middle;
}
.carousel-horizontal .carousel-nav-outside .carousel-nav-prev:disabled,
.carousel-horizontal .carousel-nav-outside .carousel-nav-next:disabled {
  opacity: 0.3;
}
.carousel-horizontal .res-arrow-prev.carousel-nav-prev,
.carousel-horizontal .res-arrow-next.carousel-nav-next {
  top: -0.1875rem;
}
.carousel-horizontal .res-arrow-prev.carousel-nav-prev {
  right: 2.1875rem;
  left: auto;
}
.carousel-horizontal .res-arrow-next.carousel-nav-next {
  right: 0.625rem;
}

.carousel-mobile {
  padding: 0;
}

.carousel-update {
  visibility: hidden;
  /* .carousel-list {
      white-space: normal;
  } */
}

.carousel--not-hidden-on-update.carousel-update {
  visibility: visible;
}

.carousel-nav-not-show > .carousel-nav {
  display: none;
}

.carousel-scroll-nav .carousel-inner {
  overflow: auto;
}

.carousel-placeholder {
  max-height: 1px;
  max-width: 1px;
}