.lp-scroll-to-top {
  position: fixed;
  left: 10px;
  bottom: 30px;
  border-radius: 50% !important;
  box-sizing: border-box;
  display: none;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 5px;
  height: 56px;
  width: 56px;
  z-index: 100;
}

.scroll-to-top-active {
  display: flex;
}