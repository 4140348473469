.properties {
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
  overflow: inherit;
}

.properties-group-name,
.properties-item-name,
.properties-item-value,
.properties-item-title-name,
.properties-item-title-value {
  margin-left: 1.0625rem;
  margin-right: 1.0625rem;
}

.properties-group-name {
  font-size: 1rem;
  margin-top: 1.0625rem;
  margin-bottom: 1.0625rem;
}

.properties-item-name,
.properties-item-value {
  font-size: 0.75rem;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.properties-item-title-name,
.properties-item-title-value {
  font-size: 0.75rem;
  margin-top: 0.625rem;
}

.properties__state {
  position: absolute;
  display: none;
}
.properties__state:checked + .properties .properties__item--state-hidden {
  display: block;
}
.properties__state:checked ~ .properties__trigger .properties__trigger--show {
  display: none;
}
.properties__state:checked ~ .properties__trigger .properties__trigger--hide {
  display: block;
}

.properties__item--state-hidden {
  display: none;
}

.properties__trigger {
  margin-top: 20px;
  margin-left: 1.0625rem;
}

.properties__trigger--show {
  display: block;
}

.properties__trigger--hide {
  display: none;
}