@charset "UTF-8";
.adv-modal-background {
  background: rgba(0, 0, 0, 0.5);
}

.adv-body-fixed-touch {
  position: fixed;
}

.adv-modal,
.adv-modal-floating {
  z-index: 999;
}
.adv-modal .adv-modal-inner,
.adv-modal-floating .adv-modal-inner {
  position: relative;
  z-index: 20;
  vertical-align: middle;
  text-align: left;
  white-space: normal;
  border-radius: 0.3125rem;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
  max-width: 80vw;
  max-height: 100vh;
  max-height: var(--min-full-height, 100vh);
  display: inline-flex;
  flex-direction: column;
}
.adv-modal .modal-header,
.adv-modal-floating .modal-header {
  padding: 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  font-size: 1.75rem;
  line-height: 1;
}
.adv-modal .modal-content,
.adv-modal-floating .modal-content {
  overflow: auto;
  padding: 10px;
  vertical-align: middle;
}
.adv-modal .modal-content::-webkit-scrollbar,
.adv-modal-floating .modal-content::-webkit-scrollbar {
  -webkit-appearance: none;
          appearance: none;
}
.adv-modal .modal-content::-webkit-scrollbar:vertical,
.adv-modal-floating .modal-content::-webkit-scrollbar:vertical {
  width: 11px;
}
.adv-modal .modal-content::-webkit-scrollbar:horizontal,
.adv-modal-floating .modal-content::-webkit-scrollbar:horizontal {
  height: 11px;
}
.adv-modal .modal-content::-webkit-scrollbar-thumb,
.adv-modal-floating .modal-content::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.4);
}
.adv-modal .modal-content::-webkit-scrollbar-track,
.adv-modal-floating .modal-content::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}
.adv-modal .modal-footer,
.adv-modal-floating .modal-footer {
  padding: 10px;
  border-top-width: 1px;
  border-top-style: solid;
  border-radius: 0 0 2px 2px;
  text-align: right;
  order: 100;
}
.adv-modal .adv-modal-close,
.adv-modal-floating .adv-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  overflow: hidden;
  width: 30px;
  height: 30px;
  border-bottom: 0;
  cursor: pointer;
  font-size: 0;
  z-index: 1;
}
.adv-modal .adv-modal-close::after,
.adv-modal-floating .adv-modal-close::after {
  font-size: 30px;
  position: absolute;
  top: 0;
  right: 0;
  text-shadow: 0 0 9px rgba(0, 0, 0, 0.5);
}

.adv-modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  white-space: nowrap;
}
.adv-modal.adv-modal-in-iframe::after {
  vertical-align: top;
}

.adv-close-outside {
  left: 100%;
  margin-left: 5px;
  top: 0;
}

.adv-modal-floating {
  position: fixed;
  top: 25%;
  /* left: 0;
  right: 0;
  bottom: 0; */
  margin: auto;
  /* top: 50%; */
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
}
.adv-modal-floating .modal-header {
  cursor: move;
}
.adv-modal-floating .adv-modal-inner {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  adv-modal-in-iframe .adv-modal-inner {
    margin-top: 6.25rem;
  }
}
@media (max-width: 48em), (max-height: 480px) {
  .adv-modal,
  .adv-modal-floating {
    bottom: auto;
    display: block;
    height: 100%;
    padding: 45px 10px 10px;
    box-sizing: border-box;
    /* .adv-modal-close {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        width: auto;
        height: 40px;
        border-bottom: 1px #ccc solid;
        border-radius: 0;
        background: #f0f0f0;
        text-align: center;
        text-decoration: none;
        text-indent: 0;
        line-height: 40px;
        cursor: pointer;
        font-size: 12px;
        color: #000;
        z-index: 999;

        &:hover {
            background: #ccc;
        }

        &:after {
            display: none;
        }
    } */
  }
  .adv-modal .modal-content,
  .adv-modal-floating .modal-content {
    flex-grow: 1;
  }
  .adv-modal .adv-modal-inner,
  .adv-modal-floating .adv-modal-inner {
    overflow: visible;
    box-sizing: border-box;
    max-width: 100vw;
    width: 100%;
    height: auto; /* для safari */
    max-height: calc(100vh - 45px);
    max-height: calc(var(--min-full-height, 100vh) - 45px);
  }
  .adv-modal .buy-one-click-dialog,
  .adv-modal-floating .buy-one-click-dialog {
    height: auto;
  }
  .adv-modal .adv-modal-close,
  .adv-modal-floating .adv-modal-close {
    left: auto;
    right: 8px;
    top: -36px;
  }
  .adv-modal .adv-modal-close::after,
  .adv-modal-floating .adv-modal-close::after {
    text-shadow: none;
  }
  .adv-modal-floating {
    top: 0;
    left: 0;
    max-width: 100%;
    transform: translate3d(0, 0, 0);
  }
  .adv-modal-floating .modal-header {
    cursor: default;
  }
  .adv-close-outside {
    margin-left: 0;
  }
}