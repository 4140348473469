.catalog-filter-block {
  display: inline-block;
  padding: 5px 10px;
  vertical-align: top;
  box-sizing: border-box;
  width: 100%;
}
.catalog-filter-block:first-child .catalog-filter-block-header {
  border-top-width: 0;
}

.catalog-filter-question-description {
  display: inline-block;
  vertical-align: middle;
}

.catalog-filter-block-content,
.catalog-filter-footer {
  padding: 0.625rem;
}

.catalog-filter-footer {
  background-color: transparent;
  text-align: right;
}

.catalog-filter-block-header {
  cursor: pointer;
  position: relative;
  padding: 0.625rem 1.25rem 0.625rem 0.625rem;
  border-width: 0 0 0.0625rem;
  border-style: solid;
}
.catalog-filter-block-header::after {
  font-family: advantshopfonts, sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  line-height: 1;
  text-align: center;
  opacity: 1;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  /* line-height: 1em; */
  font-size: 0.6875rem;
  content: "\e802";
  position: absolute;
  right: 0.4375rem;
  top: 50%;
  transform: translateY(-50%);
}
.catalog-filter-block-header.menu-dropdown-accordion-item-active::after {
  content: "\e800";
}

.catalog-filter-block-header-open::after {
  content: "\e800";
}

.catalog-filter-block-title {
  font-size: 0.75rem;
}

.catalog-filter-block-subtitle {
  font-size: 0.6875rem;
  color: #737373;
}

.catalog-filter-block-content-checkbox,
.catalog-filter-block-content-color {
  margin-left: 0.9375rem;
}

.catalog-filter-popover-button {
  text-align: right;
  margin-top: 0.3125rem;
}

.catalog-filter-row {
  position: relative;
}

.catalog-filter-popover-text {
  white-space: nowrap;
}

.filter-content {
  display: none;
}

#title-filter {
  position: absolute;
  left: -1000px;
  opacity: 0;
}

#title-filter:checked ~ .filter-content {
  display: block;
}

#title-filter:checked ~ .tab-filter {
  border-bottom: 0;
}

.tab-filter {
  display: block;
  border-bottom: 1px solid;
  padding-left: 17.333px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.filter-content .catalog-filter-header.h3 {
  display: none;
}

.filter-content .popover {
  display: none;
}

.filter-content .catalog-filter-content {
  border-radius: 0;
}

.catalog-footer--sticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 2;
}

.catalog-filter-empty {
  text-align: center;
  font-weight: bold;
  padding: 30px;
}

@media (min-width: 48em) {
  .catalog-filter-block {
    width: 50%;
  }
}
@media only screen and (min-width: 64em) {
  .catalog-filter-block {
    width: 33.3333%;
  }
}
@media only screen and (min-width: 75em) {
  .catalog-filter-block {
    width: 25%;
  }
}
.catalog-filter-block-content-range input[type=text],
.catalog-filter-block-content-range input[type=number] {
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px #e2e3e4 solid;
  vertical-align: middle;
  width: 100%;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  box-shadow: none;
}

.catalog-filter-block-content-range .input-small {
  font-size: 0.75rem;
  padding: 0.5625rem;
}

.catalog-filter-block-content-range .cs-bg-10 {
  background-color: #f8b643;
}

.lp-btn-filter {
  padding: 10px;
  font-size: 14px;
}

.lp-btn-reset-filter {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.lp-btn-reset-filter:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.catalog-filter {
  padding: 0 0 20px;
}