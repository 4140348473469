.warehouses-list-modal {
  width: 100%;
}
.warehouses-list__product-availability-count {
  font-size: 15px;
  line-height: 1.5;
}
.warehouses-list__items {
  display: grid;
  max-height: var(--height-map);
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: auto;
}
.warehouses-list__items::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: #efeff4;
}
.warehouses-list__items::-webkit-scrollbar-thumb {
  background: #c5c2c2;
  border-radius: 1px;
}
.warehouses-list__items::-webkit-scrollbar-corner {
  background: #868383;
}
.warehouses-list--only-active .warehouses-list__item {
  border-bottom: 0;
}
.warehouses-list__item {
  cursor: pointer;
  padding: 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.warehouses-list__item--active {
  background-color: #f5f5f5;
}
.warehouses-list__item:last-child {
  border-bottom: 0;
}
.warehouses-list__shop-address {
  line-height: 1.5;
  font-size: 18px;
  font-style: normal;
}
.warehouses-list__shop-address-comment {
  line-height: 1.5;
  font-size: 14px;
}
.warehouses-list__shop-name {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
}
.warehouses-list__work-time {
  font-size: 14px;
  line-height: 1.5;
}

.warehouse-details {
  padding: 10px 0;
}
.warehouse-details__inner {
  padding-bottom: 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.warehouse-details__header {
  margin-bottom: 10px;
}
.warehouse-details__shop-address {
  font-size: 18px;
  font-style: normal;
}
.warehouse-details__shop-name {
  font-size: 14px;
  font-weight: 600;
}

.product-availability-map__map {
  height: 640px;
}
.product-availability-map-modal {
  bottom: 0 !important;
  top: auto;
  height: auto;
}
.product-availability-map__spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}