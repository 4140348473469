.zoomer {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.zoomer-window {
  position: absolute;
  background-repeat: no-repeat;
  background-color: #fff;
  z-index: 5;
}

.zoomer-lens {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  z-index: 15;
}

.zoomer-processing::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
}

.zoomer-inner + .zoomer-window {
  cursor: crosshair;
}

.zoomer-right + .zoomer-window {
  margin-left: 0.3125rem;
}

.zoomer-window-title {
  position: absolute;
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  white-space: normal;
  padding: 0.3125rem;
}