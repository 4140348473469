.price-amount-list {
  color: #666;
}
.price-amount-list__row {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  background-color: #fff;
}
.price-amount-list__row:nth-child(odd) {
  background-color: #f9f9f9;
}
.price-amount-list__row:first-child {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.price-amount-list__row:last-child {
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.price-amount-list__col {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 2px;
  font-size: 12px;
  line-height: 1.5;
}
.price-amount-list__col--head {
  font-weight: 600;
}

.price-block .price-block__old-price-title {
  padding: 0 0 5px;
}
.price-block .price-block__old-price {
  -webkit-text-decoration: line-through;
  text-decoration: line-through;
}
.price-block .price-block__new-price-title {
  padding: 13px 0 5px;
}

.mobile-product-view-item__inner .price-amount-list {
  padding: 15px 0 0;
}
.mobile-product-view-item__inner .price-amount-list__col {
  padding-left: 8px;
  padding-right: 8px;
}